import { FETCH_BANNERS, FETCH_SECTIONS,FETCH_SECTIONSDATA,FETCH_BAZAR,FETCH_PROMOS,FETCH_NEWTIES,FETCH_POPULARS,FETCH_BESTSELLER } from "../actions/action-types";
import { findIndex } from "../../utils";

import { persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage';

const initialState = {
    sections: [],
    banners: [],
    bazar: []
};

const sectionReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case FETCH_SECTIONS:
            return {
                ...state,
                sections: action.sections
            };

            case FETCH_SECTIONSDATA:{
                /*
                let objIndex = state.sections.findIndex((obj => obj.id === action.id));
                if (objIndex)
                state.sections[objIndex].Products= [].concat(action.data,state.sections[objIndex].Products)
                else
                state.sections.push({'id':action.id,'Products':action.data})
                */
                state.sections.items.map( ( item, index ) =>{
                    if(item.id===action.id && state.sections.items[index]){
                        state.sections.items[index].products=action.data;
                        
                        }
                })
            return {
                ...state,
                sections: state.sections
            };}

            case FETCH_BANNERS:
            return {
                ...state,
                banners: action.banners
            };

            case FETCH_BAZAR: 
            return {
                ...state,
                bazar: action.data
            };
            case FETCH_PROMOS:
                return {
                    ...state,
                    promos: action.data
                };
                case FETCH_NEWTIES:
                    return {
                        ...state,
                        newties: action.data
                    };
                    case FETCH_POPULARS:
                        return {
                            ...state,
                            populars: action.data
                        };    
                        case FETCH_BESTSELLER:
                        return {
                            ...state,
                            bestseller: action.data
                        };    

        default:
            return state;
    }
};
const persistSectionConfig = {
    keyPrefix: "baazar-",
    key: "sections",
    storage
}

export default persistReducer( persistSectionConfig, sectionReducer );