import axios from 'axios';
import * as api from '../../utils/APIUrls';


export const login=function(
    { emailPhone,
     password,
    deviceId})  {
        /*axios.defaults.headers.common = {
          'Content-type': 'application/x-www-form-urlencoded',
        }*/
    var data=
        'client_id=Core_App&client_secret=1q2w3e*&'+        'grant_type=password&'+        'username='+emailPhone+        '&password='+password
      ;
      return axios
    .post(`${api.loginUrl}`,data)
    .then(response =>
      response.data
    )
    .catch(err => console.log(err));
    }

    export const register=function(
      { emailPhone,
       password,name,surname,latitude,longitude,address,wilayaId,cityId})  {
          /*axios.defaults.headers.common = {
            'Content-type': 'application/x-www-form-urlencoded',
          }*/

        var data={'userName': emailPhone,
      'password': password,
      'email': name+'.'+surname+"@lebaazar.com",
      'name': name,
      'surname': surname,
      'emailConfirmed': false,
      'phoneNumber': emailPhone,
      'phoneNumberConfirmed': true,
      'birthDate': '01-01-2000',
      'gender': 1,
      'clientAddress': {
        'latitude': latitude,
        'longitude': longitude,
        'address': address,
        'wilayaId': wilayaId,
        'cityId': cityId,
      }}
      /*var data=
          'client_id=Core_App&client_secret=1q2w3e*&'+        'grant_type=password&'+        'username='+emailPhone+        '&password='+password
        ;*/
        return axios
      .post(`${api.register}`,data)
      .then(response =>
        response.data
      )
      .catch(err => console.log(err));
      }

export const logOut=function(callback)  {
    axios.defaults.headers.common = { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` }
    axios
      .get(`${api.logout}`)
      .then(response => {
        if (callback != null) {
          callback(response.data);
        }
      }
      )
      .catch(err => {
      });
}

export const getUser=function async (
   emailPhone,
) {
  
    let accessToken=axios.defaults.headers.common = { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` }
    return getUserByUserName({
        username: emailPhone, accesstoken: accessToken,});
    
    
}

export const askForPhoneNumberVerify=function(
     phone,callback)  {
        axios.defaults.headers.common = { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` }
        let url=api.askForPhoneNumberVerify+ "?phoneNumber=" + phone;
        axios
      .get(`${url}`)
      .then(response => {
        if (callback != null) {
          callback(response.data);
        }
      }
      )
      .catch(err => {
        
      });
}

export const verifyPhoneNumberToken=function(token,secretCode,twilloId,callback)  {
    axios.defaults.headers.common = { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` }
        let url=api.verifyPhoneNumberToken;
        let data={
            'tokenSend': token,
            'authyId': twilloId,
        };
        axios
      .post(`${url}`,data)
      .then(response => {
        if (callback != null) {
          callback(response.data);
        }
      }
      )
      .catch(err => {
        
      });
}

export const checkIfEmailExist=function(email,callback)  {
    axios.defaults.headers.common = { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` }
        let url=api.checkEmailIfExist + "?email=" + email;
        axios
      .get(`${url}`)
      .then(response => {
        if (callback != null) {
          callback(response.data);
        }
      }
      )
      .catch(err => {
       
      });
}

export const sendEmailResetPass=function(email)  {
    axios.defaults.headers.common = { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` }
    let url=api.sendEmail;
    let data={
        "email": email,
      };
    return axios
    .post(`${url}`,data)
    .then(response => response.data)
    .catch(err => {
      
    });
}

export const sendPhoneResetPass=function(phone,callback)  {
    axios.defaults.headers.common = { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` }
    let url=api.resetPassByPhone + "?userName=" + phone;
    axios
      .get(`${url}`)
      .then(response => {
        if (callback != null) {
          callback(response.data);
        }
      }
      )
      .catch(err => {
        
      });
}

export const verifyToken=function(
     phone,  token)  {
        axios.defaults.headers.common = { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` }
        let url=api.verifyToken + "?token=" + token + "&userName=" + phone;
        let data={
            "token": token,
        "userName": phone,
        };
        return axios
      .post(`${url}`,data)
      .then(response => response.data)
      .catch(err => {
        
      });
  
}

export const resetPassByPhone=function(
     username,  newPass,  token)  {
  var abpToken = token.replaceAll("\"", "");
  axios.defaults.headers.common = { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` }
        let url=api.resetPassByPhone;
        let data={
            "userName": username,
            "token": abpToken,
            "newPassword": newPass,
        };
        return axios
      .post(`${url}`,data)
      .then(response => response.data)
      .catch(err => {
        
      });
}

export const changePass=function( username,oldPass,  newPass)  {
    axios.defaults.headers.common = { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` }
        let url=api.changePass;
        let data={
            "currentPassword": oldPass,
            "newPassword": newPass,
            "userName": username,
        };
        return axios
      .post(`${url}`,data)
      .then(response => response.data)
      .catch(err => {
        
      });
}

export const getUserByUserName=function(
    { username,  accesstoken,callback})  {
      
        axios.defaults.headers.common = { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` }
        let url=api.getUserByName + '?userName=' + username;
        return axios
      .get(`${url}`).then(response =>
        response.data
      )
      .catch(err => console.log(err));
      
}
