import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';

import LoadingOverlay from '../components/features/loading-overlay';

import { scrollTop } from '../utils';

const ElementPages = React.lazy( () => import( './elements-route.js' ) );
const ProductPages = React.lazy( () => import( './products-route.js' ) );
const ShopPages = React.lazy( () => import( './shop-route.js' ) );
const BlogPages = React.lazy( () => import( './blogs-route.js' ) );
const OtherPages = React.lazy( () => import( './others-route.js' ) );
const HomePage = React.lazy( () => import( './home-route.js' ) );
const BazarPage = React.lazy( () => import( './bazar-route.js' ) );
const NewtiesPage = React.lazy( () => import( './newties-route.js' ) );
const PromosPage = React.lazy( () => import( './promos-route.js' ) );
const PopularsPage = React.lazy( () => import( './populars-route.js' ) );
const BestSellerPage = React.lazy( () => import( './bestseller-route.js' ) );
const SellerLabPage = React.lazy( () => import( './sellerlab-route.js' ) );
const SearchResultPage = React.lazy( () => import( './search-route.js' ) );
export default function AppRoot() {
    useEffect( () => {
        scrollTop();
    }, [] )

    return (
        <React.Suspense fallback={ <LoadingOverlay /> }>
            <Switch>
                <Route path={ `${process.env.PUBLIC_URL}/elements` } component={ ElementPages } />
                <Route path={ `${process.env.PUBLIC_URL}/product` } component={ ProductPages } />
                <Route path={ `${process.env.PUBLIC_URL}/shop` } component={ ShopPages } />
                <Route path={ `${process.env.PUBLIC_URL}/blog` } component={ BlogPages } />
                <Route path={ `${process.env.PUBLIC_URL}/views` } component={ OtherPages } />
                <Route path={ `${process.env.PUBLIC_URL}/bazar` } component={ BazarPage } />
                <Route path={ `${process.env.PUBLIC_URL}/newties` } component={ NewtiesPage } />
                <Route path={ `${process.env.PUBLIC_URL}/promos` } component={ PromosPage } />
                <Route path={ `${process.env.PUBLIC_URL}/populars` } component={ PopularsPage } />
                <Route path={ `${process.env.PUBLIC_URL}/bestseller` } component={ BestSellerPage } />
                <Route path={ `${process.env.PUBLIC_URL}/search` } component={ SearchResultPage } />
                <Route path={ `${process.env.PUBLIC_URL}/sellerlab` } component={ SellerLabPage } />
                <Route path={ `${process.env.PUBLIC_URL}/` } component={ HomePage } />
            </Switch>
        </React.Suspense>
    )
}