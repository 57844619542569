export const PLAY = "fas fa-play-circle fa-lg";
export const PAUSE = "fas fa-pause-circle fa-lg";
export const EMPTY_HEART = "far fa-heart mr-4";
export const FILLED_HEART = "fas fa-heart";



export const getGenreFromCategories = categories => {
  let genre = "";
  if (categories) {
    for (var i = 0; i < categories.length; i++) {
      if (categories[i].info !== null) {
        if (i === 0) {
          genre = categories[i].info ? categories[i].info.name : "";
        } else {
          genre =
            genre + ", " + categories[i].info ? categories[i].info.name : " ";
        }
      }
    }
  }

  return genre;
};

export const getProductWithId = (listProducts, id) => {
  for (var i = 0; i < listProducts.length; i++) {
    if (listProducts[i].id === id) {
      return listProducts[i];
    }
  }
  return "";
};

//To get random element from banners
export const randomFct = productsList => {
  productsList = productsList.filter(function(ele) {
    return ele != null;
  });
  const keys = Object.keys(productsList);
  let i = keys.length - 1;
  const j = Math.floor(Math.random() * i);
  return productsList[keys[j]];
};

