import { combineReducers } from 'redux';
import searchReducer from './searchReducer';
import productReducer from './products';
import sectionsReducer from './sections';
import cartReducer from './cart';
import wishlistReducer from './wishlist';
import compareReducer from './compare';
import filterReducer from './filter';
import categoriesReducer from './categories';
import modalReducer from './modal';
import accountReducer from './account';

const rootReducer = combineReducers( {
    data: productReducer,
    cartlist: cartReducer,
    wishlist: wishlistReducer,
    compare: compareReducer,
    filters: filterReducer,
    modal: modalReducer,
    search: searchReducer,
    sections: sectionsReducer,
    categories:categoriesReducer,
    account:accountReducer,
} );

export default rootReducer;