import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyD42SMhLHHqv9WHcnQn50mfP0FJdffS_tQ",
    authDomain: "baazar-795b3.firebaseapp.com",
    projectId: "baazar-795b3",
    storageBucket: "baazar-795b3.appspot.com",
    messagingSenderId: "805841029366",
    appId: "1:805841029366:web:5cfbf7a118abb5eb9584d7",
    measurementId: "G-ERZT1X4SP8"
  };
  var app=firebase.initializeApp(firebaseConfig);
  
  
  export default firebase;