import * as auth from '../api/auth'
import * as homeApi from '../api/home'
import * as types from './action-types'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import * as store from "../../utils/storage";
import * as appService from '../actions';

export const handleLogin = data => ( {
    type: types.LOGIN,
    data:data,
} );
export const handleUserInfo = user => ( {
    type: types.GET_USER,
    data:user,
} );
export const login = ( username,password ) => async (dispatch) => {
    
    console.log("auth:"+username);
    auth.login({emailPhone:username,password:password,deviceId:"web"}).then( data => {
        try{
        data.username=username;
        dispatch( handleLogin(data)  );
        store.setSession({authResult:data});
        console.log("auth:"+JSON.stringify(data));
        dispatch(getUser(username));
        }catch{}
        return data ;
    } )
}
export const register = ( username,password,name,surname,latitude,longitude,address,wilayaId,cityId ) => async (dispatch) => {
    
    console.log("auth:"+username);
    auth.register({emailPhone:username,password:password,name:name,surname,latitude,longitude,address,wilayaId,cityId}).then( data => {
        try{
        data.username=username;
        dispatch( handleLogin(data)  );
        store.setSession({authResult:data});
        console.log("auth:"+JSON.stringify(data));
        dispatch(getUser(username));
        }catch{}
        return data ;
    } )
}
export const getUser = (username)=> async (dispatch) => {
    if(!username) username=store.getSession()?.userName;
    console.log("username:"+username)
    if(username){
    auth.getUser(username)
    .then((data)=>{
        console.log("user:"+JSON.stringify(data));
    store.setSession({authResult: data});
    dispatch( handleUserInfo({user:data})  );
    dispatch(appService.syncCart(store.getSession()?.id));
    
}
)}}
// checks if the user is authenticated
export const isAuthenticated = () => {
    // Check whether the current time is past the
    // access token's expiry time
    let expiresAt = JSON.parse(localStorage.getItem("expires_at"));
    console.log(expiresAt);
    return new Date().getTime() < expiresAt;
  };

  export const logout = ( ) => async (dispatch) => {
    store.setLogout();
    auth.logOut().then( data => {
        return data ;
    } )
}