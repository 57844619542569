import { ADD_TO_CART, REMOVE_FROM_CART, CHANGE_QTY, CHANGE_SHIPPING, REFRESH_STORE, GET_CART,SYNC_CART, EMPTY_CART } from "../actions/action-types";
import { findIndex } from "../../utils";
import { persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage';
import * as store from "../../utils/storage";
import * as api from '../api'
import firebase from '../../store/firestore';
import { getAnalytics, logEvent } from "firebase/analytics";
import ReactPixel from 'react-facebook-pixel';

const initialState = {
    cartId:null,
    cart: [],
    shipping: "standard"
}
const analytics = getAnalytics();
function cartReducer ( state = initialState, action ) {
    let newCart=[]
    let stringCart=""
    let productItem;
    switch ( action.type ) {
        case SYNC_CART:
            let oldCartItems=action.cart?.productsInCart;
            let cartItems=state.cart;
            for(let i=0;i<state.cart.length;i++)
                if(!oldCartItems?.find(c=>c.id===state.cart[i].id)){
                    api.getProductByID(state.cart[i].id,store.getSession()?.id).then((data)=>{
                        productItem = data?.productItems?.find(x=>x.qty>=action.qty);
                        api.addToCart(store.getSession()?.id,productItem?.id,state.cart[i].qty).then( data => {
                            return data ;
                        } )
                    })
                }
            for(let i=0;i<oldCartItems.length;i++)
                if(!state.cart?.find(c=>c.id===oldCartItems[i].id)){
                    cartItems =[
                        ...cartItems,
                        {
                            ...oldCartItems[i],
                            qty: oldCartItems[i].cartItems.find(x=>x!==undefined).qty,
                            sum: ( oldCartItems[i].promoPrice>0 ? oldCartItems[i].promoPrice : oldCartItems[i].price ) * oldCartItems[i].cartItems.find(x=>x!==undefined).qty
                        }
                    ];   
                }
                
            return {
                ...state,
                cartId:action.cart?.id,
                cart: cartItems
            }
        case ADD_TO_CART:
            const productId = action.product.id;
            logEvent(analytics, 'add_to_cart');
            ReactPixel.track("AddToCart", {});
            const db = firebase.firestore();
            /*db.settings({
            });*/
            // create a table(collection) called 'users' in cloud firestore in order to store info
              db.collection('cart').add({
                date: Date.now(),
                session:store.getSession()?.id??"",
                product: JSON.stringify(action.product??""),
                productItem:JSON.stringify(action.productItem??'')
            });
            if ( findIndex( state.cart, product => product?.id === productId ) !== -1 ) {
                const cart = state.cart.reduce( ( cartAcc, product ) => {
                    if ( product.id === productId ) {
                        cartAcc.push( { ...product, qty: parseInt( product.qty ) + parseInt( action.qty ), sum: ( 0 < product.promoPrice ? product.promoPrice : product.price ) * ( parseInt( product.qty ) + parseInt( action.qty ) ) } ) // Increment qty
                    } else {
                        cartAcc.push( product )
                    }
                    return cartAcc
                }, [] )

                return { ...state, cart }
            }
            productItem=action.productItem;
            console.log("xxx: "+productItem)
            if(!action.productItem){
                api.getProductByID(productId,store.getSession()?.id).then((data)=>{
                    console.log("xxxx:")
                    console.log(data?.productItems)
                    productItem = data?.productItems?.find(x=>x.qty>=action.qty);
                    console.log("yyyy:")
                    console.log(productItem)
                    api.addToCart(store.getSession()?.id,productItem?.id,action.qty).then( data => {
                
                        return data ;
                    } )
                })
                
            }else{
                api.addToCart(store.getSession()?.id,productItem?.id,action.qty).then( data => {
                
                    return data ;
                } )
            }
            newCart =[
                ...state.cart,
                {
                    ...action.product,
                    qty: action.qty,
                    sum: ( action.product.promoPrice>0 ? action.product.promoPrice : action.product.price ) * action.qty
                }
            ];
            stringCart = JSON.stringify(newCart);
            store.local.setItem("cart", stringCart)
            return {
                ...state,
                cart: newCart
            }
            case GET_CART:
                let productsInCart=action.cart?.productsInCart;
                let localCart = store.local.getItem("cart");
                for (let i=0; i>productsInCart?.length;i++){
                    productsInCart[i]["qty"] = action.cart?.productsInCart[i].cartItems.find(x=>x!==undefined).qty;
                    productsInCart[i]["sum"] = action.cart?.productsInCart[i].cartItems.find(x=>x!==undefined).qty*( productsInCart[i].promoPrice>0 ? productsInCart[i].promoPrice : productsInCart[i].price );
                }
                console.log("cart:"+[
                    ...state.cart,
                    localCart,
                    productsInCart
                ]); 

            return {
                    ...state,
                    cartId:action.cart?.id,
                    cart: action.cart?.productsInCart? productsInCart: [localCart]
                }

        case REMOVE_FROM_CART:
            newCart=state.cart.filter( item => item.id !== action.productId );
            stringCart = JSON.stringify(newCart);
            store.local.setItem("cart", stringCart)
            api.deleteFromCart(action.productId,state.cartId);
            return {
                ...state,
                cart: newCart
            };

        case CHANGE_QTY:
            const cart = state.cart.reduce( ( cartAcc, product ) => {
                if ( product.id === action.productId ) {
                    cartAcc.push( { ...product, qty: action.qty, sum: (  0 < product.promoPrice ? product.promoPrice : product.price ) * action.qty } ) // Increment qty
                } else {
                    cartAcc.push( product )
                }
                return cartAcc;
            }, [] )
            stringCart = JSON.stringify(cart);
            store.local.setItem("cart", stringCart)
            return { ...state, cart };

        case REFRESH_STORE:
            return { ...state, cart: [],cartId:null, shipping: "standard" };

        case EMPTY_CART:
            console.log("empty cart")
            return { ...state, cart: [],cartId:null, shipping: "standard" };

        case CHANGE_SHIPPING:
            return { ...state, shipping: action.shipping };

        default:
            return state;
    }
}

const persistConfig = {
    keyPrefix: "baazar-",
    key: "cartlist",
    storage
}

export default persistReducer( persistConfig, cartReducer );