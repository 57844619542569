import React, { useEffect, useTransition, Suspense } from 'react';
import * as api from '../api'
import * as homeApi from '../api/home'
import * as types from './action-types'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

// get all products
export const getAllSections = () => dispatch => {
    homeApi.getHomeSectionsContent().then( sections => {
        console.log('sections: '+JSON.stringify(sections));
        dispatch( receiveSections( sections ) );
        return sections;
    } )
}

// recieve products
export const receiveSections = ({sections}) => ( {
    type: types.FETCH_SECTIONS,
    sections,
} );

// recieve products
export const receiveMoreSectionsData = (sections,id,data) => ( {
    type: types.FETCH_SECTIONSDATA,
    id,
    data
} );

export const receiveMoreProducts = products => ( {
    type: types.FETCH_PRODUCTS,
    products
} );

// get all products
export const getAllSectionsLazy = () => dispatch => {
    homeApi.getHomeSectionsHeader().then( banners => {
        //console.log('banners: '+JSON.stringify(banners));
        dispatch( receiveBanners( banners ) );
        dispatch( receiveSections( {sections: banners} ) );
        /*if(banners!==undefined)
        banners.items.forEach(e => {
            if(e.discriminator==="ProductsSection"){
                homeApi.getMoreProducts(e.id,0).then(data=>{
                //console.log("more products:"+JSON.stringify(data));
                if(data!==undefined){
                dispatch( receiveMoreProducts( data ) );
                banners.items.map( ( item, index ) =>{
                    if(item.id===e.id && banners.items[index]){
                        banners.items[index].products=data;
                        
                        }
                })}
                if(e.type===1) //récement ajoutés
                dispatch( receiveNewties( {data: data} ) );
                if(e.type===2) //article populaires
                dispatch( receivePopulars( {data: data} ) );
                if(e.id===13) //article les plus vendus
                dispatch( receiveBestseller( {data: data} ) );

                dispatch( receiveSections( {sections: banners} ) );
            });
            }
        });*/
        return banners;
    } )
}

export const getBazar = () => dispatch => {
    homeApi.getBazar().then( data => {
        //console.log('bazar: '+JSON.stringify(data));
        dispatch( receiveBazar( {data:data?.items} ) );
        dispatch( receiveMoreProducts( {products:data?.items} ) );
        return data;
    } )
}

export const getPromos = () => dispatch => {
    homeApi.getPromos().then( data => {
        //console.log('promo: '+JSON.stringify(data));
        dispatch( receivePromos( {data:data?.items} ) );
        dispatch( receiveMoreProducts( {products:data?.items} ) );
        return data;
    } )
}

export const search = (query,skipCount=0) => dispatch => {
    homeApi.search(query,skipCount).then( data => {
        console.log('search: '+JSON.stringify(data));
        dispatch( receiveSearchResult( {data:data?.items} ) );
        dispatch( receiveMoreProducts( {products:data?.items} ) );
        return data;
    } )
}

// recieve products
export const receiveBanners = banners => ( {
    type: types.FETCH_BANNERS,
    banners
} );

export const receiveBazar = data => ( {
    type: types.FETCH_BAZAR,
    data
} );

export const receivePromos = data => ( {
    type: types.FETCH_PROMOS,
    data
} );

export const receiveNewties = data => ( {
    type: types.FETCH_NEWTIES,
    data
} );

export const receivePopulars = data => ( {
    type: types.FETCH_POPULARS,
    data
} );

export const receiveBestseller = data => ( {
    type: types.FETCH_BESTSELLER,
    data
} );

export const receiveSearchResult = data => ( {
    type: types.SEARCH_PRODUCT,
    data
} );