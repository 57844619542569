import { RECEIVE_PRODUCTS, SHOW_QUICKVIEW,SHOW_DETAILVIEW, CLOSE_QUICKVIEW, FETCH_PRODUCTS,SEARCH_PRODUCT } from "../actions/action-types";
import { findIndex } from "../../utils";

import { persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage';

const initialState = {
    products: [],
    productDetail: [],
    quickView: false,
    searchResult:[]
};

const productReducer = ( state = initialState, action ) => {
    let index=-1;
    switch ( action.type ) {
        case RECEIVE_PRODUCTS:
            console.log("products:"+[].concat(state.products,action.products).length)
            return {
                ...state,
                products: [].concat(state.products,action.products).reduce((filtered, item) => {
                    if( !filtered.some(filteredItem => filteredItem.id ===item.id) )
                      filtered.push(item)
                    return filtered
                  }, [])
            };
            case FETCH_PRODUCTS:
                //console.log("products:"+[].concat(state.products,action.products).length)
                return {
                    ...state,
                    products: [].concat(state.products,action.products)
                };

                case SHOW_DETAILVIEW:
                    //console.log('debug : detailed view product:'+JSON.stringify(action.productDetail))
                    index = findIndex( state.products, product => product && product !== null && product.id === action.productId );
                    if ( -1 !== index ) {
                        const item = state.products[ index ];
                        item.pictures=[].push(item.principalThumbnailPath)
                        return {
                            ...state,
                            product: item,
                            productDetail:action.productDetail
                        };
                    }else{
                        return {
                            ...state,
                            productDetail:action.productDetail
                        };
                    }
                    break;

                case SHOW_QUICKVIEW:
                    
                    index = findIndex( state.products, product => product!==undefined&&product.id === action.productId );
            if ( -1 !== index ) {
                const item = state.products[ index ];
                if(item!==undefined)
                item.pictures=[].push(item.principalThumbnailPath)
                console.log('debug : quick view product XXX')
                return {
                    ...state,
                    quickView: true,
                    product: item,
                    productDetail: item
                };
            }
            break;

        case CLOSE_QUICKVIEW:
            return { ...state, quickView: false };
        
            case SEARCH_PRODUCT:
                return { ...state, searchResult:action.data };
        default:
            return state;
    }
};
const persistConfig = {
    keyPrefix: "baazar-",
    key: "products",
    storage
}

export default persistReducer( persistConfig, productReducer );