import { FETCH_CATEGORIES,FETCH_CATEGORIE_PRODUCTS,FETCH_SUBCATEGORIE_PRODUCTS } from "../actions/action-types";

import { persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage';

const initialState = {
    categories: [],
    categoriesDetails: [],
    subCategoriesDetails: [],
    brands: []
};

const categoriesReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case FETCH_CATEGORIES:
            return {
                ...state,
                categories: action.categories
            };
        case FETCH_CATEGORIE_PRODUCTS:
            console.log("product categorie:"+JSON.stringify(action.data))
            try{
            let objIndex = state.categories.items.findIndex((obj => obj.id === action.id));
                state.categories.items[objIndex].Products= [].concat(action.data.products,state.categories.items[objIndex].Products)
            }catch{}
            return {
                ...state,
                categories: state.categories,
                categoriesDetails: [].concat(action.data,state.categoriesDetails)
            }
            case FETCH_SUBCATEGORIE_PRODUCTS:
                //console.log("categorie:"+JSON.stringify(action.data))
                return {
                    ...state,
                    categories: state.categories,
                    subCategoriesDetails: [].concat(action.data,state.subCategoriesDetails)
                }
        default:
            return state;
    }
};
const persistConfig = {
    keyPrefix: "baazar-",
    key: "categories",
    storage
}

export default persistReducer( persistConfig, categoriesReducer );