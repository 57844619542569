export const baseUrl                ="https://api.lebaazar.com";//"http://161.97.122.71";
export const serverPortIdentity     ="44370";
export const ridersHub              =baseUrl+"/signalr-hubs/rider";
export const search                 =baseUrl+'/api/app/search/results';
export const baseUrlImages          =baseUrl;
export const loginUrl               ="http://api.lebaazar.com"+':'+serverPortIdentity+'/connect/token';
export const baseApiRoles           =baseUrl+'/api/identity/roles/';
export const getUserByName          =baseUrl+'/api/app/clientUser/byUserName';
export const logout                 =baseUrl+'/connect/disconnect';
export const addStore               =baseUrl+'/api/app/store';
export const addProduct             =baseUrl+'/api/app/products';
export const addUser                =baseUrl+'/api/app/clientUser';
export const register                =baseUrl+'/api/app/clientUser';
export const becameSeller           =baseUrl+'/api/app/seller';
export const getCategoriesURL          =baseUrl+'/api/app/categories/fullCategory';
export const getNotifications       =baseUrl+'/api/app/notification/notification';
export const markNotificationAsRead =baseUrl+'/api/app/notification/';
export const getHomeSections       =baseUrl+'/api/app/homeSection/sectionsIn';
export const getStoreSections      =baseUrl+'/api/app/storeHomeSection/lessList';
export const getStoreByID          =baseUrl+'/api/app/store/storeLessDetails';
export const getProductByIDURL        =baseUrl+'/api/app/products/';
export const getProductByID        =baseUrl+'/api/app/products/';
export const getFeaturesURL           =baseUrl+'/api/app/products/dynamicProductModelDto';
export const deleteProduct         =baseUrl+'/api/app/products/';
export const deleteStore           =baseUrl+'/api/app/store/';
export const addBrand              =    baseUrl+'/api/app/brand/fromMobile';
export const updateLogoStore       =    baseUrl+'/api/app/store/logoStore';
export const updateCoverStore      =    baseUrl+'/api/app/store/coverStore';
export const updateInfoStore       =    baseUrl+'/api/app/store/infoStore';
export const updateInfoProduct     =    baseUrl+'/api/app/products/infoMobile';
export const updateItemsProduct    =    baseUrl+'/api/app/products/itemMobile';
export const addImagePrincipal     =    baseUrl+'/api/app/products/principleMobile';
export const deleteImagePrincipal  =    baseUrl+'/api/app/products/principleMobile';
export const addImageDetailed      =    baseUrl+'/api/app/products/detailedMobile';
export const deleteImageDetailed   =    baseUrl+'/api/app/products/detailedMobile';
export const getFeaturesUpdate     =    baseUrl+'/api/app/products/dynamicUpdateProductInfo';
export const getFeaturesByID       =    baseUrl+'/api/app/products/';
export const getProductsCount      =    baseUrl+'/api/app/products/productsCount';
export const getItemsProductByIDURL   =    baseUrl+'/api/app/order/dynamicForOrder';
export const getCartByIDURL           =    baseUrl+'/api/app/cart/cartByClientUserId/';
export const addCart               =    baseUrl+'/api/app/cart/manyCartItems/';
export const deleteFromCartURL        =    baseUrl+'/api/app/cart/byProductId';
export const updateCart            =    baseUrl+'/api/app/cart/cartOfProduct';
export const validateCartURL          =    baseUrl+'/api/app/cart/validateCart';
export const getOrdersURL             =    baseUrl+'/api/app/order/ordersByClientUserId/';
export const getOrdersHistoryURL      =    baseUrl+'/api/app/order/clientUserHistoric/';
export const getOrdersSellerHistory=    baseUrl+'/api/app/order/sellerHistoric/';
export const getOrderURL              = baseUrl+'/api/app/order/';
export const likeProductURL           =baseUrl+'/api/app/products/likeProductFromMobile';
export const getProductsByCat      =baseUrl+'/api/app/products/productsByCategoryId/';
export const getProductsBySubCat   =baseUrl+'/api/app/products/productsBySubCategoriesId/';
export const orderNowURL              =baseUrl+'/api/app/order/orderNow/';
export const addAddress            =baseUrl+'/api/app/clientAddress';
export const updateStoreAddress    =baseUrl+'/api/app/store/addressStore';
export const deleteAddress         =baseUrl+'/api/app/clientAddress';
export const userInfo              =baseUrl+'/api/app/clientUser/userInfo';
export const favoriteProducts      =baseUrl+'/api/app/products/favoriteProducts/';
export const brandSection          =baseUrl+'/BrandSection';
export const updateProfilePic      =baseUrl+'/api/app/clientUser/updateProfilePicture';
export const acceptOrderBySeller   =baseUrl+'/api/app/order/accepted';
export const recoverOrderByRider   =baseUrl+'/api/app/order/inProgress';
export const deliverOrderByRider   =baseUrl+'/api/app/order/deliveredWithSuccess';
export const rejectOrderBySeller   =baseUrl+'/api/app/order/sellerReject';
export const acceptOrderItemBySeller =baseUrl+'/api/app/order/';
export const rejectOrderItemBySeller =baseUrl+'/api/app/order/';
export const acceptOrderByRider      =     baseUrl+'/api/app/order/riderChoosed/';
export const rejectOrderByRider      =baseUrl+'/api/app/order/riderReject/';
export const sendToken             =    baseUrl+'/api/app/clientUser/fireBaseToken';
export const becameRider           = baseUrl+'/api/app/rider';
export const addPromo              = baseUrl+'/api/app/promo';
export const changePass            =    baseUrl+'/api/app/clientUser/changePassword';
export const sendEmail             =    baseUrl+'/api/app/clientUser/generatePasswordResetTokenByEmail';
export const forgotPassword             =    baseUrl+'/api/app/clientUser/askForPasswordReset';
export const verifyToken           =    baseUrl+'/api/app/clientUser/verifyToken';
export const resetPassByPhone      =    baseUrl+'/api/app/clientUser/resetPasswordByUserName';
export const getMyStores           =    baseUrl+'/api/app/store/myStores/';
export const createPromo           = baseUrl+'/api/app/promo';
export const getPromos             =    baseUrl+'/api/app/products/inPromo';
export const getTopSales           =    baseUrl+'/api/app/products/topSold';
export const getBazar              =    baseUrl+'/api/app/products/productsPersonnelStores';
export const getProductsByBrand    =    baseUrl+'/api/app/products/productsByBrandId/';
export const askForPhoneNumberVerify =    baseUrl+'/api/app/clientUser/askForPhoneNumberVerify';
export const checkEmailIfExist =    baseUrl+'/api/app/clientUser/verifyEmailIfNotExist';
export const verifyPhoneNumberToken =    baseUrl+'/api/app/clientUser/verifyPhoneNumberToken/';
export const productsBySectionId =    baseUrl+'/api/app/productHomeSection/moreByProductSectionId';
export const StoreSection =    baseUrl+'/api/app/storeHomeSection/moreByStoreSectionId';
export const getOrdersRiderHistory =    baseUrl+'/api/app/order/currentDelivery/';
export const getOrdersRiderDeliveries =    baseUrl+'/api/app/order/riderDeliveries/';
export const getStoresSearch =    baseUrl+'/api/app/search/resultsStore';
export const cancelOrderURL =    baseUrl+'/api/app/order/cancelled';
export const getCurrentOrdersClient =    baseUrl+'/api/app/order/clientOrders/';
export const getCurrentOrdersRider =    baseUrl+'/api/app/order/riderOrders/';
export const getCurrentOrdersSeller =    baseUrl+'/api/app/order/sellerOrders/';
export const deliveryPrice =    baseUrl+'/api/app/order/calculateDeliveryPrice';
export const updateOrderAddress =    baseUrl+'/api/app/store/updateOrderAddress'; //TODO
export const increaseBalance =      baseUrl+'/api/app/wallet/increaseBalanceByVoucherCode/';

export const rateProductAPI =      baseUrl+'/api/app/';//TODO
export const rateStoreAPI =      baseUrl+'/api/app/';//TODO
export const rateRiderAPI =      baseUrl+'/api/app/';//TODO

export const getRelatedProducts = baseUrl+'/api/app/'; //todo
export const getRelatedStores = baseUrl+'/api/app/'; //todo
export const allSections =baseUrl+'/api/app/homeSection/sectionsInWithOutItems?Page=1';