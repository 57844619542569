import { LOGIN, LOGOUT,GET_USER } from "../actions/action-types";
import { findIndex } from "../../utils";

import { persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage';
import { userInfo } from "../../utils/APIUrls";

import { isAuthenticated } from "../../utils/functions";

const initialState = {
    account:{
        access_token:"",
        userInfo:{},
        isAuthenticated:false
    } 
};

const accountReducer = ( state = initialState, action ) => {
    let index=-1;
    switch ( action.type ) {
        case LOGIN:
            action.data.isAuthenticated=true;

            return {
                ...state,
                account: 
                    action.data,
            };
            case GET_USER:
                return {
                    ...state,
                    
                    account: { ...state.account, 
                    userInfo:action.data,
                    isAuthenticated:true,
                    }
                };

                case LOGOUT:
                    
                        return {
                            access_token:"",
        userInfo:{},
        isAuthenticated:false
                        };
                    
                    

                
        default:
            return state;
    }
};
const persistConfig = {
    keyPrefix: "baazar-",
    key: "account",
    storage
}

export default persistReducer( persistConfig, accountReducer);